import React from 'react'
import { Typography, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import { Button, TextField, Checkbox, FormControlLabel } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

const useStyles = makeStyles()(theme => ({
  root: {
    paddingTop: '85px',
    paddingBottom: '50px',
  },
  textField: {
    width: '400px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  textArea: {
    width: '650px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const ContactSection = props => {
  const { classes } = useStyles()
  return (
    <div {...props} className={classes.root}>
      <Typography variant="h3" id="kontakt">Kontakt</Typography>
      <form
        autoComplete={'off'}
        name="contact"
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input name="bot-field" type="hidden" />
        <input name="form-name" type="hidden" value="contact" />
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TextField
              className={classes.textField}
              id="name"
              name="name"
              label="Ihr Name"
              variant="filled"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              id="email"
              name="email"
              label="Ihr E-Mail-Adresse"
              type="email"
              variant="filled"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              className={classes.textField}
              id="subject"
              name="subject"
              label="Betreff"
              variant="filled"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              multiline
              minRows={10}
              className={classes.textArea}
              id="message"
              name="message"
              label="Ihre Nachricht"
              variant="filled"
              required
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox name="privacyConsent" color="primary" required />
              }
              labelPlacement="end"
              label="Sie erklären sich damit einverstanden, dass Ihre Daten zur Bearbeitung Ihres Anliegens verwendet werden."
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              endIcon={<SendIcon />}
            >
              Senden
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default ContactSection
